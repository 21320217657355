/* eslint-disable global-require */

import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware } from 'connected-react-router';
import history from '../util/history';
import rootEpic from './redux-root-epic';
import rootReducer from './redux-root-reducer';
import * as api from '../api/service';

const router = routerMiddleware(history);
const epic = createEpicMiddleware({
  dependencies: {
    api,
  },
});

let middleware = [router, epic];

if (process.env.NODE_ENV !== 'production') {
  const { createLogger } = require('redux-logger');
  const logger = createLogger();
  middleware = [...middleware, logger];
}

const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);

export default function configureStore(initialState) {
  const store = createStoreWithMiddleware(rootReducer, initialState);
  epic.run(rootEpic);
  return store;
}
