import { normalize } from 'normalizr';
import { projectListSchema } from '../../../schema/project';
import { SORT_PROJECT_BY__DEFAULT } from '../../../util/project-sort-options';
import { STATUS__DEFAULT } from '../../../util/project-status';
import {
  PROJECT_DETAIL_ERROR,
  PROJECT_DETAIL_SUCCESS,
  PROJECT_FILTER_BY_ACTIVITY,
  PROJECT_FILTER_BY_COUNTRY,
  PROJECT_FILTER_BY_EVALUATION,
  PROJECT_FILTER_BY_QUERY,
  PROJECT_FILTER_BY_STATUS,
  PROJECT_LIST,
  PROJECT_LIST_ERROR,
  PROJECT_LIST_SUCCESS,
  PROJECT_SET_PAGE,
  PROJECT_SET_PAGE_SIZE,
  PROJECT_SORT,
  PROJECT_SORT_REVERSE,
} from '../action-types';

export const pageSizes = [10, 20, 50, 100];

const initialState = {
  list: [],
  projects: {},
  pageSize: pageSizes[0],
  filterQuery: '',
  filterStatus: STATUS__DEFAULT,
  reverseSort: false,
  sortBy: SORT_PROJECT_BY__DEFAULT,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PROJECT_LIST: {
      return {
        ...state,
        loadingList: true,
        list: [],
      };
    }

    case PROJECT_LIST_SUCCESS: {
      const {
        meta: {
          total,
        },
        projects,
      } = action.payload;
      const normalizedData = normalize(projects, projectListSchema);
      console.log(normalizedData);
      const { result } = normalizedData;

      return {
        ...state,
        loadingList: false,
        list: result,
        listError: undefined,
        total,
      };
    }

    case PROJECT_LIST_ERROR:
      return {
        ...state,
        loadingList: false,
        listError: action.error,
        list: [],
        total: undefined,
        page: undefined,
      };

    case PROJECT_SET_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }

    case PROJECT_SET_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.pageSize,
      };
    }

    case PROJECT_FILTER_BY_QUERY: {
      return {
        ...state,
        filterQuery: action.query,
      };
    }

    case PROJECT_FILTER_BY_STATUS: {
      return {
        ...state,
        filterStatus: action.status,
      };
    }

    case PROJECT_FILTER_BY_EVALUATION: {
      return {
        ...state,
        filterEvaluation: action.evaluation,
      };
    }

    case PROJECT_FILTER_BY_COUNTRY: {
      return {
        ...state,
        filterCountries: action.countries,
      };
    }

    case PROJECT_FILTER_BY_ACTIVITY: {
      return {
        ...state,
        filterActivity: {
          hasActivity: action.hasActivity,
          period: action.period,
        },
      };
    }

    case PROJECT_SORT: {
      return {
        ...state,
        sortBy: action.field,
      };
    }

    case PROJECT_SORT_REVERSE: {
      return {
        ...state,
        reverseSort: action.reverse,
      };
    }

    case PROJECT_DETAIL_SUCCESS: {
      const { projects } = state;
      const { payload: project } = action;
      return {
        ...state,
        projects: {
          ...projects,
          [project.id]: {
            ...projects[project.id],
            ...project,
            quota: project.quota && project.quota * 1024 * 1024,
            total_media_size: parseInt(project.total_media_size, 10),
            total_template_size: parseInt(project.total_template_size, 10),
          },
        },
      };
    }

    case PROJECT_DETAIL_ERROR: {
      const { projects } = state;
      const {
        parent: {
          id,
        },
        error,
      } = action;

      return {
        ...state,
        projects: {
          ...projects,
          [id]: {
            error,
          },
        },
      };
    }

    default:
  }

  return state;
}
