import {
  map, filter,
} from 'rxjs/operators';
import { buildErrorNotification } from '../util/notificationBuilder';
// Action Types

const NOTIFICATION_ENQUEUE = '@nofitication/ENQUEUE';

const NOTIFICATION_CLEAR = '@nofitication/CLEAR';

const actionTypes = {
  NOTIFICATION_ENQUEUE,
  NOTIFICATION_CLEAR,
};

// Action Creators

const enqueueNotification = (notification) => ({
  type: NOTIFICATION_ENQUEUE,
  notification: {
    ...notification,
    key: notification.key || Date.now() + Math.random(),
  },
});

const clearNotification = (key) => ({
  type: NOTIFICATION_CLEAR,
  key,
});

const actionCreators = {
  enqueueNotification,
  clearNotification,
};

// Epics

const errorNotificationEpic = (action$) => action$.pipe(
  filter((action) => action.error && action.parent && !action.parent.silentError),
  map(({ error, parent: { errorNotification } }) => {
    if (errorNotification) {
      return enqueueNotification(errorNotification(error));
    }

    return enqueueNotification(buildErrorNotification(error));
  }),
);

const epics = {
  errorNotificationEpic,
};

// reducer

const initalState = {
  queue: [],
};

const reducer = (state = initalState, { type, ...action }) => {
  switch (type) {
    case NOTIFICATION_ENQUEUE:
      return {
        ...state,
        queue: [
          ...state.queue,
          {
            ...action.notification,
          },
        ],
      };

    case NOTIFICATION_CLEAR:
      return {
        ...state,
        queue: state.queue.filter(
          (notification) => notification.key !== action.key,
        ),
      };

    default:
      return state;
  }
};

// exports

export {
  actionTypes,
  actionCreators,
  epics,
};

export default reducer;
