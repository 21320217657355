import {
  APP_CLOSE_DRAWER,
  APP_GET_SETTINGS,
  APP_GET_SETTINGS_ERROR,
  APP_GET_SETTINGS_SUCCESS,
  APP_OPEN_DRAWER,
  APP_SET_PALETTE_TYPE,
} from '../action-types';

const initialState = {
  drawerOpen: true,
  paletteType: 'light',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case APP_GET_SETTINGS: {
      return {
        ...state,
        loadingSettings: true,
      };
    }

    case APP_GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        loadingSettings: false,
        settings: action.payload,
        settingsError: undefined,
      };
    }

    case APP_GET_SETTINGS_ERROR: {
      return {
        ...state,
        loadingSettings: false,
        settings: undefined,
        settingsError: action.error,
      };
    }

    case APP_OPEN_DRAWER: {
      return {
        ...state,
        drawerOpen: true,
      };
    }

    case APP_CLOSE_DRAWER: {
      return {
        ...state,
        drawerOpen: false,
      };
    }

    case APP_SET_PALETTE_TYPE: {
      return {
        ...state,
        paletteType: action.paletteType,
      };
    }

    default:
  }

  return state;
}
