/* eslint-disable import/prefer-default-export */
export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#009688',
    },
    secondary: {
      main: '#f50057',
    },
  },
};
