import React, { Suspense, lazy } from 'react';
// import { Provider } from 'react-redux';
import { useSelector } from 'react-redux';
// import { ConnectedRouter } from 'connected-react-router';
import CssBaseline from '@material-ui/core/CssBaseline';
// import JssProvider from 'react-jss/lib/JssProvider';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
// import { Auth0Provider } from '@auth0/auth0-react';
// import configureStore from './conf/redux-store';
// import history from './util/history';
// import themeOptions from './util/theme.json';
import { themeOptions } from './util/dsplay-theme';
import Notifier from './components/Notifier';
// import { auth0 as authConfig } from './util/env';
import useTrackMounting from './hooks/use-mount-tracking';

// const onRedirectCallback = (appState) => {
//   // Use the router's history module to replace the url
//   history.replace(appState?.returnTo || window.location.pathname);
// };

const Main = lazy(() => import('./pages/main'));

// const store = configureStore();

function App() {
  useTrackMounting('APP');
  const currentPaletteType = useSelector(({ app: { paletteType } }) => paletteType);

  const theme = createMuiTheme({
    ...themeOptions,
    palette: {
      ...themeOptions.palette,
      type: currentPaletteType,
    },
    typography: {
      useNextVariants: true,
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      {/* <div role="app" /> */}
      <SnackbarProvider
        classes={{
          variantSuccess: 'snackbar success',
          // variantError: 'snackbar error',
          // variantWarning: 'snackbar warning',
          // variantInfo: 'snackbar info',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="root">
          <CssBaseline />
          <Notifier />
          <Suspense fallback={<div />}>
            <Main />
          </Suspense>
        </div>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

App.propTypes = {
  // classes: PropTypes.shape({}).isRequired,
};

export default App;
