import {
  PROJECT_DETAIL,
  PROJECT_FILTER_BY_ACTIVITY,
  PROJECT_FILTER_BY_COUNTRY,
  PROJECT_FILTER_BY_EVALUATION,
  PROJECT_FILTER_BY_QUERY,
  PROJECT_FILTER_BY_STATUS,
  PROJECT_LIST,
  PROJECT_SET_PAGE,
  PROJECT_SET_PAGE_SIZE,
  PROJECT_SORT,
  PROJECT_SORT_REVERSE,
  PROJECT_TRIGGER_LIST,
} from './action-types';

export const listProjects = () => ({
  type: PROJECT_LIST,
});

export const setProjectListPage = (page) => ({
  type: PROJECT_SET_PAGE,
  page,
});

export const setProjectListPageSize = (pageSize) => ({
  type: PROJECT_SET_PAGE_SIZE,
  pageSize,
});

export const filterProjectsByQuery = (query) => ({
  type: PROJECT_FILTER_BY_QUERY,
  query,
});

export const filterProjectsByStatus = (status) => ({
  type: PROJECT_FILTER_BY_STATUS,
  status,
});

export const filterProjectsByEvaluation = (evaluation) => ({
  type: PROJECT_FILTER_BY_EVALUATION,
  evaluation,
});

export const filterProjectsByCountry = (countries) => ({
  type: PROJECT_FILTER_BY_COUNTRY,
  countries,
});

export const filterProjectsByActivity = (hasActivity, period) => ({
  type: PROJECT_FILTER_BY_ACTIVITY,
  hasActivity,
  period,
});

export const sortProjects = (field) => ({
  type: PROJECT_SORT,
  field,
});

export const setProjectsReverseSort = (reverse) => ({
  type: PROJECT_SORT_REVERSE,
  reverse,
});

export const triggerListProjects = () => ({
  type: PROJECT_TRIGGER_LIST,
});

export const detailProject = (id) => ({
  type: PROJECT_DETAIL,
  id,
});
