import {
  map, mergeMap, catchError, delay, takeUntil, switchMap,
} from 'rxjs/operators';
import {
  from, of, forkJoin, timer,
} from 'rxjs';
import { ofType } from 'redux-observable';

// action types
const DO_NOTHING_ACTION = '@foo/DO_NOTHING';

// internal stuff
const CANCEL_SUFFIX = '_CANCEL';
const SUCCESS_SUFFIX = '_SUCCESS';
const ERROR_SUFFIX = '_ERROR';
const INVALID_SUFFIX = '_INVALID';
const MIN_DELAY = 0;

// helper functions
const cancelType = (type) => type + CANCEL_SUFFIX;
const successType = (type) => type + SUCCESS_SUFFIX;
const errorType = (type) => type + ERROR_SUFFIX;
const invalidType = (type) => type + INVALID_SUFFIX;

const successAction = (actionType, parent) => (payload) => ({
  type: successType(actionType),
  parent,
  payload,
});

const errorAction = (actionType, parent) => (error) => ({
  type: errorType(actionType),
  parent,
  error,
});

const invalidAction = (actionType, parent) => () => ({
  type: invalidType(actionType),
  parent,
});

const defaultValidation = () => true;

const buildEpic = (
  actionType,
  fetch,
  {
    valid = defaultValidation,
    override = false,
    minDelay = 0,
  } = {},
) => (action$, state$, dependencies) => action$.pipe(
  ofType(actionType),
  // filter(action => fallbackAction || valid(action, state$.value)),
  (override ? switchMap : mergeMap)((action) => {
    if (valid(action, state$.value)) {
      return forkJoin([
        from(fetch(action, state$.value, dependencies)),
        timer(minDelay),
      ]).pipe(
        delay(MIN_DELAY),
        map(([payload]) => successAction(actionType, action)(payload)),
        takeUntil(action$.pipe(
          ofType(cancelType(actionType)),
        )),
        catchError((error) => of(errorAction(actionType, action)(error))),
      );

      // return from(fetch(action, state$.value, dependencies)).pipe(
      //   delay(MIN_DELAY),
      //   map(successAction(actionType, action)),
      //   takeUntil(action$.pipe(
      //     ofType(cancelType(actionType)),
      //   )),
      //   catchError((error) => of(errorAction(actionType, action)(error))),
      // );
    }
    return of(invalidAction(actionType, action)());
  }),
);

const doNothing = () => ({
  type: DO_NOTHING_ACTION,
});

const actionCreators = {
  doNothing,
};

// exports

export {
  actionCreators,
  cancelType,
  successType,
  errorType,
  invalidType,
  buildEpic,
  successAction,
  errorAction,
  invalidAction,
};
