import {
  mapTo, debounceTime,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
  PROJECT_DETAIL,
  PROJECT_FILTER_BY_ACTIVITY,
  PROJECT_FILTER_BY_COUNTRY,
  PROJECT_FILTER_BY_EVALUATION,
  PROJECT_FILTER_BY_QUERY,
  PROJECT_FILTER_BY_STATUS,
  PROJECT_LIST,
  PROJECT_SET_PAGE,
  PROJECT_SET_PAGE_SIZE,
  PROJECT_SORT,
  PROJECT_SORT_REVERSE,
  PROJECT_TRIGGER_LIST,
} from './action-types';
import { buildEpic } from '../../util/redux-observable-helpers';
import { listProjects, setProjectListPage } from './action-creators';
import { STATUS_ANY } from '../../util/project-status';

export const triggerListProjectsEpic = (action$) => action$.pipe(
  ofType(
    PROJECT_TRIGGER_LIST,
    PROJECT_SET_PAGE,
    PROJECT_SORT,
    PROJECT_SORT_REVERSE,
  ),
  debounceTime(50),
  mapTo(listProjects()),
);

export const resetPageEpict = (action$) => action$.pipe(
  ofType(
    PROJECT_SET_PAGE_SIZE,
    PROJECT_FILTER_BY_QUERY,
    PROJECT_FILTER_BY_STATUS,
    PROJECT_FILTER_BY_EVALUATION,
    PROJECT_FILTER_BY_COUNTRY,
    PROJECT_FILTER_BY_ACTIVITY,
  ),
  mapTo(setProjectListPage(1)),
);

export const listProjectsEpic = buildEpic(
  PROJECT_LIST,
  (
    // action
    _,
    // state
    {
      project: {
        page,
        pageSize,
        filterQuery,
        filterStatus,
        filterEvaluation,
        filterCountries,
        filterActivity,
        sortBy,
        reverseSort,
      },
    },
    // deps
    {
      api,
    },
  ) => api.listProjects({
    page,
    pageSize,
    query: filterQuery,
    status: filterStatus !== STATUS_ANY ? filterStatus : undefined,
    evaluation: filterEvaluation,
    country: filterCountries,
    activity: filterActivity,
    sortBy,
    reverseSort,
  }),
  {
    override: true,
    minDelay: 500,
  },
);

export const detailProjectEpic = buildEpic(
  PROJECT_DETAIL,
  ({ id }, _, { api }) => api.detailProject(id),
);
