import { useEffect } from 'react';
import { Logger } from '../util/log';

const logger = new Logger('hooks:mountTracking');

function useTrackMounting(name, {
  log = logger.debug,
} = {}) {
  useEffect(() => {
    log('MOUNTED', name);
    return () => log('UNMOUNTED', name);
  }, [log, name]);
}

export default useTrackMounting;
