// import axios from 'axios';
import { setup } from 'axios-cache-adapter';
import { Logger } from './log';

const log = new Logger('axios');

let getToken;

export function setTokenProvider(providerFunction) {
  getToken = providerFunction;
}

let baseURL;
if (process.env.NODE_ENV === 'production') {
  baseURL = process.env.REACT_APP_API_URL;
  log.info('PRODUCTION MODE');
} else {
  baseURL = 'https://dev-api.dsplay.io/admin';
}

// Create `axios` instance with pre-configured `axios-cache-adapter` attached to it
const axiosInstance = setup({
  baseURL,
  // `axios-cache-adapter` options
  cache: {
    maxAge: 15 * 60 * 1000,
    exclude: {
      query: false,
    },
  },
});

const publicPaths = [
];

axiosInstance.interceptors.request.use(async (originalRequest) => {
  log.debug('originalRequest:', originalRequest);

  if (!originalRequest.url.startsWith('http') && !publicPaths.includes(originalRequest.url)) {
    const token = await getToken();

    if (token) {
      log.debug('adding token to request');

      const headers = {
        ...originalRequest.headers,
        Authorization: `Bearer ${token}`,
      };

      return {
        ...originalRequest,
        headers,
      };
    }
  }

  return originalRequest;
}, (err) => Promise.reject(err));

export default axiosInstance;
