import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Auth0Provider } from '@auth0/auth0-react';
import configureStore from './conf/redux-store';
import { auth0 as authConfig } from './util/env';
import history from './util/history';

import App from './App';
import * as serviceWorker from './serviceWorker';

const store = configureStore();

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider
      domain={authConfig.domain}
      clientId={authConfig.clientId}
      redirectUri={window.location.origin}
      scope="email openid email read:projects"
      audience={authConfig.audience}
      onRedirectCallback={onRedirectCallback}
    >
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Auth0Provider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
// defineCustomElements(window);
