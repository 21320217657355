import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import user, { actionTypes as userActions } from '../ducks/user';
import app from '../ducks/app/reducer';
import history from '../util/history';
import notification from '../ducks/notification';
import project from '../ducks/project/reducer';

const appReducer = combineReducers({
  router: connectRouter(history),
  app,
  user,
  notification,
  project,
});

const rootReducer = (state, action) => {
  let currentState = state;
  if (action.type === userActions.USER_LOG_OUT_SUCCESS) {
    currentState = {
      router: state.router,
    };
  }

  return appReducer(currentState, action);
};

export default rootReducer;
