import DataLoader from 'dataloader';
import axios from '../util/axios';

import { Logger } from '../util/log';
import { SORT_PROJECT_BY__DEFAULT } from '../util/project-sort-options';
import { STATUS__DEFAULT } from '../util/project-status';

const log = new Logger('api');

export async function getProfile() {
  const result = await axios.get('/api/profile');
  return result.data;
}

export async function logOut() {
  // TODO: remove
  return true;
}

// async function createFilter(filter) {
//   const result = await axios.post('/api/filter', filter);
//   return result.data;
// }

const projectFields = [
  'name',
  'creation',
  'key',
  'quota',
  'expiration',
  'active',
  'max_terminals',
  'domains',
  'custom',
  'city',
  'state',
  'country',
  'country_code',
  'address',
  'address_num',
  'zipcode',
  'phone',
  'website',
  'evaluation',
  'last_terminal_access',
  'last_user_access',
  'dead',
  'terminal_status_notification_enabled',
  'terminal_status_notification_emails',
  'owner_emails',
  'owner_email',
  'total_media_size',
  'total_template_size',
  'user_count',
  'terminal_count',
];

async function loadProjects(ids) {
  log.debug('[data-loader] loading projects:', ids);

  const result = await axios.get('/project-details', {
    params: {
      ids: ids.join(','),
      fields: projectFields.join(','),
    },
  });

  const results = ids.map((id) => {
    if (!result.data[id]) return new Error('not found');
    return result.data[id];
  });

  return results;
}

const projectLoader = new DataLoader((keys) => loadProjects(keys));

export async function getSettings() {
  const response = await axios.get('/settings');
  return response.data;
}

export async function listProjects({
  page,
  pageSize,
  query,
  country,
  status,
  sortBy,
  reverseSort,
} = {}) {
  const response = await axios.get('/projects', {
    params: {
      page,
      pageSize,
      q: query !== '' ? query : undefined,
      country,
      status: status !== STATUS__DEFAULT ? status : undefined,
      sortBy: sortBy !== SORT_PROJECT_BY__DEFAULT ? sortBy : undefined,
      reverseSort,
    },
  });

  return response.data;
}

export async function detailProject(id) {
  // return projectsLoader.loadMany(ids);
  return projectLoader.load(id);
}

export async function listFileUploads({
  statuses,
  page,
  pageSize,
}) {
  const result = await axios.get('/api/uploads', {
    params: {
      page,
      pageSize,
      statuses,
    },
  });
  return result.data;
}

export async function uploadFile(file, {
  onIdCreated = (id) => id,
  onProgress = (progress) => progress,
  onFinish = () => false,
  onError = (error) => error,
}) {
  try {
    const result = await axios.get('/api/uploads/s3PutUrl', {
      params: {
        name: file.name,
      },
    });
    const { id, uploadURL } = result.data;
    onIdCreated(id);

    log.debug('s3UploadURL:', uploadURL);

    await axios.put(uploadURL, file, {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: onProgress,
    });

    onFinish();
  } catch (e) {
    onError(e);
  }
}

export async function reportError(error) {
  console.error(error);
  // TODO send to server
}
