import { combineEpics } from 'redux-observable';
import { epics as notification } from '../ducks/notification';
import * as project from '../ducks/project/epics';
import * as app from '../ducks/app/epics';

const toArray = (obj) => Object.keys(obj).map((key) => obj[key]);

export default combineEpics(...[
  ...toArray(notification),
  ...toArray(project),
  ...toArray(app),
]);
