import { errorType, successType } from '../../util/redux-observable-helpers';

export const PROJECT_LIST = '@project/LIST';
export const PROJECT_LIST_SUCCESS = successType(PROJECT_LIST);
export const PROJECT_LIST_ERROR = errorType(PROJECT_LIST);

export const PROJECT_SET_PAGE = '@project/SET_PAGE';
export const PROJECT_SET_PAGE_SIZE = '@project/SET_PAGE_SIZE';

export const PROJECT_FILTER_BY_QUERY = '@project/FILTER_BY_QUERY';
export const PROJECT_FILTER_BY_STATUS = '@project/FILTER_BY_STATUS';
export const PROJECT_FILTER_BY_EVALUATION = '@project/FILTER_BY_EVALUATION';
export const PROJECT_FILTER_BY_COUNTRY = '@project/FILTER_BY_COUNTRY';
export const PROJECT_FILTER_BY_ACTIVITY = '@project/FILTER_BY_ACTIVITY';

export const PROJECT_SORT = '@project/SORT';
export const PROJECT_SORT_REVERSE = '@project/SORT_REVERSE';

export const PROJECT_DETAIL = '@project/DETAIL';
export const PROJECT_DETAIL_SUCCESS = successType(PROJECT_DETAIL);
export const PROJECT_DETAIL_ERROR = errorType(PROJECT_DETAIL);

export const PROJECT_TRIGGER_LIST = '@project/TRIGGER_LIST';
