export const env = process.env.NODE_ENV || 'development';

console.log('host:', window.location.host);

export const isProd = () => env === 'production';
export const isDebug = () => !window.location.host.startsWith('admin');

export const envLabel = () => {
  // TODO: improve this ugly logic
  let label;

  if (window.location.host.startsWith('dev')) {
    label = 'dev';
  } else if (window.location.host.startsWith('staging')) {
    label = 'staging';
  } else if (window.location.host.startsWith('localhost')) {
    label = 'localhost';
  }

  return label;
};

export const auth0 = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN || 'dev-dsplay.us.auth0.com',
  clientId: process.env.REACT_APP_AUTH0_CLIEND_ID || 'a1ARU8oSokB3cARYQdyFDLfgIZpKVDF5',
  audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://dsplay.tv/admin-dev',
};
