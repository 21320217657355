import { errorType, successType } from '../../util/redux-observable-helpers';

export const APP_GET_SETTINGS = '@app/GET_SETTINGS';
export const APP_GET_SETTINGS_SUCCESS = successType(APP_GET_SETTINGS);
export const APP_GET_SETTINGS_ERROR = errorType(APP_GET_SETTINGS);

export const APP_OPEN_DRAWER = '@app/OPEN_DRAWER';
export const APP_CLOSE_DRAWER = '@app/CLOSE_DRAWER';

export const APP_SET_PALETTE_TYPE = '@app/SET_PALETTE_TYPE';
