import { successType, errorType, buildEpic } from '../util/redux-observable-helpers';

import * as api from '../api/service';

// Action Types

const USER_LOG_OUT = '@user/LOG_OUT';
const USER_LOG_OUT_SUCCESS = successType(USER_LOG_OUT);
const USER_LOG_OUT_ERROR = errorType(USER_LOG_OUT);

const USER_GET_PROFILE = '@user/GET_PROFILE';
const USER_GET_PROFILE_SUCCESS = successType(USER_GET_PROFILE);
const USER_GET_PROFILE_ERROR = errorType(USER_GET_PROFILE);

const actionTypes = {
  USER_LOG_OUT,
  USER_LOG_OUT_SUCCESS,
  USER_LOG_OUT_ERROR,

  USER_GET_PROFILE,
  USER_GET_PROFILE_SUCCESS,
  USER_GET_PROFILE_ERROR,
};

// Action Creators

const getUserProfile = () => ({
  type: USER_GET_PROFILE,
  silentError: true,
});

const logOut = () => ({
  type: USER_LOG_OUT,
});

const actionCreators = {
  getUserProfile,
  logOut,
};

// reducer

const initialState = {
  user: undefined,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case USER_GET_PROFILE_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        profileError: undefined,
      };
    }

    case USER_GET_PROFILE_ERROR: {
      return {
        ...state,
        user: undefined,
        profileError: action.error,
      };
    }

    case USER_LOG_OUT:
      return {
        ...state,
        loggingOut: true,
        user: undefined,
      };

    case USER_LOG_OUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        logOutError: undefined,
      };
    case USER_LOG_OUT_ERROR:
      return {
        ...state,
        loggingOut: false,
        logOutError: action.error,
      };
    default:
  }

  return state;
}

// epics

const getProfileEpic = buildEpic(
  USER_GET_PROFILE,
  () => api.getProfile(),
);

const logOutEpic = buildEpic(
  USER_LOG_OUT,
  () => api.logOut(),
);

const epics = [
  getProfileEpic,
  logOutEpic,
];

export default reducer;

export {
  actionTypes,
  actionCreators,
  epics,
};
